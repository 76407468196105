import React, { useState, useCallback } from 'react';

const GRID_SIZE = 16;
const MINE_COUNT = 10;

function Minesweeper() {
  const countNeighborMines = useCallback((grid, row, col) => {
    let count = 0;
    for (let r = -1; r <= 1; r++) {
      for (let c = -1; c <= 1; c++) {
        const newRow = row + r;
        const newCol = col + c;
        if (newRow >= 0 && newRow < GRID_SIZE && newCol >= 0 && newCol < GRID_SIZE) {
          if (grid[newRow][newCol].isMine) count++;
        }
      }
    }
    return count;
  }, []);

  const initializeGrid = useCallback(() => {
    const newGrid = Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill().map(() => ({
      isMine: false,
      isRevealed: false,
      neighborCount: 0,
    })));

    // Place mines
    let minesPlaced = 0;
    while (minesPlaced < MINE_COUNT) {
      const row = Math.floor(Math.random() * GRID_SIZE);
      const col = Math.floor(Math.random() * GRID_SIZE);
      if (!newGrid[row][col].isMine) {
        newGrid[row][col].isMine = true;
        minesPlaced++;
      }
    }

    // Calculate neighbor counts
    for (let row = 0; row < GRID_SIZE; row++) {
      for (let col = 0; col < GRID_SIZE; col++) {
        if (!newGrid[row][col].isMine) {
          newGrid[row][col].neighborCount = countNeighborMines(newGrid, row, col);
        }
      }
    }

    return newGrid;
  }, [countNeighborMines]);

  const [grid, setGrid] = useState(initializeGrid);
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);

  const resetGame = useCallback(() => {
    setGrid(initializeGrid());
    setGameOver(false);
    setGameWon(false);
  }, [initializeGrid]);

  const checkWinCondition = useCallback((newGrid) => {
    const allNonMinesRevealed = newGrid.every(row =>
      row.every(cell => cell.isMine || cell.isRevealed)
    );
    if (allNonMinesRevealed) {
      setGameWon(true);
      setTimeout(resetGame, 2000); // Relance la partie après 2 secondes
    }
  }, [resetGame]);
  
  const revealCell = useCallback((grid, row, col) => {
    if (row < 0 || row >= GRID_SIZE || col < 0 || col >= GRID_SIZE || grid[row][col].isRevealed) return;

    grid[row][col].isRevealed = true;

    if (grid[row][col].neighborCount === 0) {
      for (let r = -1; r <= 1; r++) {
        for (let c = -1; c <= 1; c++) {
          revealCell(grid, row + r, col + c);
        }
      }
    }
  }, []);

  const revealAllMines = useCallback((grid) => {
    for (let row = 0; row < GRID_SIZE; row++) {
      for (let col = 0; col < GRID_SIZE; col++) {
        if (grid[row][col].isMine) {
          grid[row][col].isRevealed = true;
        }
      }
    }
  }, []);

  const handleCellClick = useCallback((row, col) => {
    if (gameOver || gameWon) return;

    setGrid(prevGrid => {
      const newGrid = prevGrid.map(row => [...row]);
      if (newGrid[row][col].isMine) {
        setGameOver(true);
        revealAllMines(newGrid);
        setTimeout(resetGame, 2000); // Relance la partie après 2 secondes
      } else {
        revealCell(newGrid, row, col);
        checkWinCondition(newGrid);
      }
      return newGrid;
    });
  }, [gameOver, gameWon, checkWinCondition, resetGame, revealAllMines, revealCell]);

  const handleCellRightClick = useCallback((e, row, col) => {
    e.preventDefault();
    if (gameOver || gameWon) return;

    setGrid(prevGrid => {
      const newGrid = JSON.parse(JSON.stringify(prevGrid));
      const cell = newGrid[row][col];
      if (!cell.isRevealed) {
        cell.isFlagged = !cell.isFlagged;
      }
      return newGrid;
    });
  }, [gameOver, gameWon]);

  return (
    <div className="minesweeper">
      <div className="minesweeper-header">
        <div className="mine-count">{String(MINE_COUNT).padStart(3, '0')}</div>
        <button className="smiley-button" onClick={resetGame}>
          {gameOver ? '😵' : gameWon ? '😎' : '🙂'}
        </button>
        <div className="timer">000</div>
      </div>
      <div className="minesweeper-grid">
        {grid.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {row.map((cell, colIndex) => (
              <button
                key={`${rowIndex}-${colIndex}`}
                className={`minesweeper-cell ${cell.isRevealed ? 'revealed' : ''} ${cell.isRevealed && cell.isMine ? 'mine' : ''} ${cell.isFlagged ? 'flagged' : ''}`}
                onClick={() => handleCellClick(rowIndex, colIndex)}
                onContextMenu={(e) => handleCellRightClick(e, rowIndex, colIndex)}
              >
                {cell.isRevealed && !cell.isMine && cell.neighborCount > 0 ? cell.neighborCount : ''}
                {cell.isRevealed && cell.isMine ? '💣' : ''}
                {!cell.isRevealed && cell.isFlagged ? '🚩' : ''}
              </button>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Minesweeper;
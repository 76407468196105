import { useState, useEffect, useRef } from 'react';

function useChartDimensions(initialWidth, padding = 0) {
  const [dimensions, setDimensions] = useState({ width: initialWidth, height: 0 });
  const chartRef = useRef(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartRef.current) {
        const container = chartRef.current.parentElement;
        if (container.clientWidth > 0 && container.clientHeight > 0) {
            setDimensions({
                width: container.clientWidth - (padding * 2),
                height: container.clientHeight - (padding * 2)
            });
        } else {
            setTimeout(updateDimensions, 500);
        }
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return [dimensions, chartRef];
}

export default useChartDimensions;
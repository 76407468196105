import React, { useState, useEffect, useRef, useCallback } from 'react';
import './StockMarket.css';
import { useSocket } from './SocketContext';
import StockList from './ExchangeApp/StockList';
import StockChartContainer from './ExchangeApp/StockChartContainer';
import TradeForm from './ExchangeApp/TradeForm/TradeForm';

function StockMarket() {
  const [selectedStock, setSelectedStock] = useState(null);
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [userBalance, setUserBalance] = useState(10000);
  const socket = useSocket();
  const [sampleRate, setSampleRate] = useState(1);

  useEffect(() => {
    if (!socket) return;

    socket.on('userBalance', (balance) => {
      setUserBalance(balance);
    });

    return () => {
      socket.off('userBalance');
    };
  }, [socket]);

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);

    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);
  
  const handleStockSelect = useCallback((stock) => {
    setSelectedStock(stock);
  }, []);

  const handleTrade = useCallback((action, quantity) => {
    if (socket && selectedStock) {
      socket.emit('trade', {
        action,
        stockName: selectedStock.name,
        quantity: parseInt(quantity, 10)
      });
    }
  }, [socket, selectedStock]);

  const handleSampleRateChange = (rate) => {
    setSampleRate(rate);
  };

  return (
    <div className="stock-market">
      <div className="stock-container">
        <StockList 
          onSelectStock={handleStockSelect} 
        />
        <div className="stock-chart-and-trade">
          <div ref={chartContainerRef} className="stock-chart-container">
            {selectedStock && (
              <StockChartContainer 
                stock={selectedStock} 
                width={chartWidth} 
                sampleRate={sampleRate}
                onSampleRateChange={handleSampleRateChange}
              />
            )}
          </div>
          {selectedStock && (
            <TradeForm 
              stock={selectedStock}
              onTrade={handleTrade}
              userBalance={userBalance}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default StockMarket;
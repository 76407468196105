import React, { useEffect, useState } from 'react';
import { useTradingData } from './TradingContext';

function PhoneDirectory() {
const { contacts } = useTradingData();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (window !== window.parent) {
        window.parent.postMessage({ 
          type: 'requestAllPhoneNumbers'
        }, 'https://cfx-nui-nolosha_trading');
      } else {
        console.error('Impossible de vendre en mode local');
      }
  }, [contacts]);

  const filteredContacts = contacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.phone.includes(searchTerm)
  );

  function formatName(name) {
    const words = name.split(' ');
    if (words.length === 1) {
      return words[0];
    } else {
      return `${words[0]} ${words[1] ? words[1][0] + '.' : ''}`;
    }
  }

  function formatPhoneNumber(phone) {
    return phone.replace(/(\d{3})(?=\d)/g, '$1 ').trim();
  }

  return (
    <div className="phone-directory">
      <input
        type="text"
        placeholder="Rechercher un contact..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      <ul className="contact-list">
        {filteredContacts.map(contact => {
          return (
            <li key={contact.id} className="contact-item">
              <span className="contact-name">{formatName(contact.name)}</span>
              <span className="contact-phone">{formatPhoneNumber(contact.phone)}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default PhoneDirectory;
import { useState, useEffect, useCallback } from 'react';
import { useSocket } from '../SocketContext';

function useStockData(stock) {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const socket = useSocket();

  const processData = useCallback((history) => {
    const processedData = history
      .filter(item => 
        item && 
        !isNaN(item.open) && 
        !isNaN(item.high) && 
        !isNaN(item.low) && 
        !isNaN(item.close)
      )
      .map(item => ({
        date: new Date(item.time),
        open: Number(item.open),
        high: Number(item.high),
        low: Number(item.low),
        close: Number(item.close),
        volume: Number(item.volume) || 0
      }))
      .sort((a, b) => a.date - b.date);

    if (processedData.length === 0) {
      return null;
    }

    return processedData;
  }, []);

  useEffect(() => {
    if (!socket || !stock) return;

    const handleStockHistory = ({ stockName, history }) => {
      if (stockName === stock.name) {
        const processedData = processData(history);
        if (processedData) {
          setChartData(processedData);
          setIsLoading(false);
        } else {
          console.error("Impossible de traiter les données de l'historique");
          setIsLoading(false);
        }
      }
    };

    socket.on('stockHistory', handleStockHistory);

    return () => {
      socket.off('stockHistory', handleStockHistory);
    };
  }, [socket, stock, processData]);

  useEffect(() => {
    if (!socket || !stock) return;

    socket.emit('getStockHistory', stock.name);
  }, [socket, stock]);

  return { chartData, isLoading };
}

export default useStockData;
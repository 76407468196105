import React, { useState, useEffect } from 'react';

function BuyForm({ stock, userBalance, leverages, selectedAccount, taxRate }) {
  const [buyQuantity, setBuyQuantity] = useState('1');
  const [leverage, setLeverage] = useState(1);
  const [totalValue, setTotalValue] = useState(0);
  const [newBalance, setNewBalance] = useState(0);

  useEffect(() => {
    const calculateValues = () => {
      const quantity = parseFloat(buyQuantity) || 0;
      const baseCost = quantity * stock.price;
      const taxAmount = baseCost * (taxRate / 100);
      const totalCost = Math.round(baseCost + taxAmount);
      setTotalValue(totalCost);
      setNewBalance(userBalance - totalCost);
    };

    calculateValues();
  }, [buyQuantity, leverage, stock.price, userBalance, taxRate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newBalance >= 0) {
      if (window !== window.parent) {
        window.parent.postMessage({ 
          type: 'buyStock', 
          stockName: stock.name, 
          quantity: parseInt(buyQuantity, 10), 
          leverage: parseInt(leverage, 10),
          account: selectedAccount
        }, 'https://cfx-nui-nolosha_trading');
      } else {
        console.error('Impossible d\'acheter en mode local');
      }
      /*socket.emit('buyStock', {
        stockName: stock.name,
        quantity: parseFloat(buyQuantity),
        leverage: parseFloat(leverage)
      });*/
      setBuyQuantity('1');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="buyQuantity">Quantité :</label>
          <input 
            type="number" 
            id="buyQuantity" 
            value={buyQuantity} 
            onChange={(e) => setBuyQuantity(e.target.value)}
            min="1"
            required
            className="win95-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="leverage">Levier :</label>
          <select 
            id="leverage" 
            value={leverage || undefined} 
            onChange={(e) => setLeverage(e.target.value)}
            className="win95-select"
          >
            {leverages.map((lev) => (
              <option key={lev} value={lev}>{lev}x</option>
            ))}
          </select>
        </div>
      </div>
      <p className="transaction-impact">
        Coût total (incluant {taxRate}% de taxe) : ${totalValue.toFixed(2)}
        <br />
        Nouveau solde : ${newBalance.toFixed(2)}
      </p>
      <button 
        type="submit" 
        className="win95-button" 
        disabled={newBalance < 0}
      >
        Acheter
      </button>
    </form>
  );
}

export default BuyForm;
import React, { useState } from 'react';
import './TradeForm.css';  // Assurez-vous que le chemin est correct

function SellForm({ stock, selectedAccount, ownedStocks }) {
  const [sellQuantity1, setSellQuantity1] = useState(0);
  const [sellQuantity2, setSellQuantity2] = useState(0);
  const [sellQuantity5, setSellQuantity5] = useState(0);
  const [sellQuantity10, setSellQuantity10] = useState(0);

  const ownedQuantity = ownedStocks ? ownedStocks[stock.name.toLowerCase()] || 0 : 0;
  const ownedQuantityx2 = ownedStocks ? ownedStocks[stock.name.toLowerCase() + "x2"] || 0 : 0;
  const ownedQuantityx5 = ownedStocks ? ownedStocks[stock.name.toLowerCase() + "x5"] || 0 : 0;
  const ownedQuantityx10 = ownedStocks ? ownedStocks[stock.name.toLowerCase() + "x10"] || 0 : 0;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (window !== window.parent) {
      window.parent.postMessage({ 
        type: 'sellStock',
        stockName: stock.name,
        quantity: parseInt(sellQuantity1, 10) || 0,
        quantity_x2: parseInt(sellQuantity2, 10) || 0,
        quantity_x5: parseInt(sellQuantity5, 10) || 0,
        quantity_x10: parseInt(sellQuantity10, 10) || 0,
        account: selectedAccount,
      }, 'https://cfx-nui-nolosha_trading');
    } else {
      console.error('Impossible de vendre en mode local');
    }
    setSellQuantity1(0);
    setSellQuantity2(0);
    setSellQuantity5(0);
    setSellQuantity10(0);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="form-group">
          <div className="sell-grid">
            <div className="sell-item">
              <label htmlFor="sellQuantity1">Actions:</label>
              <input 
                type="number"
                id="sellQuantity1" 
                value={sellQuantity1} 
                onChange={(e) => setSellQuantity1(e.target.value)}
                max={ownedQuantity}
                min="0"
                required
                className="win95-input"
              />
              <span>Quantité : <b>{ownedQuantity}</b></span>
            </div>
            <div className="sell-item">
              <label htmlFor="sellQuantity2">Levier x2:</label>
              <input 
                type="number"
                id="sellQuantity2" 
                value={sellQuantity2} 
                onChange={(e) => setSellQuantity2(e.target.value)}
                max={ownedQuantityx2}
                min="0"
                required
                className="win95-input"
              />
              <span>Quantité : <b>{ownedQuantityx2}</b></span>
            </div>
            <div className="sell-item">
              <label htmlFor="sellQuantity5">Levier x5:</label>
              <input 
                type="number"
                id="sellQuantity5" 
                value={sellQuantity5} 
                onChange={(e) => setSellQuantity5(e.target.value)}
                max={ownedQuantityx5}
                min="0"
                required
                className="win95-input"
              />
              <span>Quantité : <b>{ownedQuantityx5}</b></span>
            </div>
            <div className="sell-item">
              <label htmlFor="sellQuantity10">Leviers x10:</label>
              <input 
                type="number"
                id="sellQuantity10" 
                value={sellQuantity10} 
                onChange={(e) => setSellQuantity10(e.target.value)}
                max={ownedQuantityx10}
                min="0"
                required
                className="win95-input"
              />
              <span>Quantité : <b>{ownedQuantityx10}</b></span>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" className="win95-button" disabled={!sellQuantity1 && !sellQuantity2 && !sellQuantity5 && !sellQuantity10}>Vendre</button>
    </form>
  );
}

export default SellForm;
export const margin = { left: 0, right: 55, top: 0, bottom: 24 };

export const retroColors = {
  background: "#c0c0c0",
  border: "#808080",
  text: "#000000",
  gridLines: "#808080",
  candleUp: "#008000",
  candleDown: "#ff0000",
  volume: "#0000ff",
  ema12: "#800080",
  ema26: "#ffa500",
  rsi: "#FFD700",
  macd: "#FF69B4",
  bollingerBand: "#00CED1",
  // Nouvelles valeurs ajoutées
  elderRay: {
    bullPower: "#008000",
    bearPower: "#ff0000",
  },
  macdHistogram: {
    positive: "#2CA02C",
    negative: "#D62728",
  },
  rsiLine: "#8884D8",
  volumeProfile: {
    up: "rgba(38, 166, 154, 0.3)",
    down: "rgba(239, 83, 80, 0.3)",
  },
  crosshair: {
    stroke: "#FFFFFF",
    strokeOpacity: 0.8,
    strokeWidth: 1,
  },
  tooltip: {
    fill: "#303030",
    opacity: 0.9,
    stroke: "#000000",
    strokeWidth: 1,
    label: "#9BC0DD",
    labelFill: "#FFFFFF",
  },
};

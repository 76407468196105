import React, { useState, useEffect } from 'react';
import './TradeForm.css';
import { useSocket } from '../../SocketContext';
import { useTradingData } from '../../TradingContext';
import BuyForm from './BuyForm';
import SellForm from './SellForm';

function TradeForm({ stock }) {
  const [activeTab, setActiveTab] = useState('buy');
  const [notification, setNotification] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedAccountBalance, setSelectedAccountBalance] = useState(0);
  const socket = useSocket();
  const { bankAccounts, leverages, tax, ownedStocks } = useTradingData();

  useEffect(() => {
    socket.on('buySuccess', () => {
      setNotification({ type: 'success', text: 'Achat réussi !' });
    });

    socket.on('buyError', (data) => {
      setNotification({ type: 'error', text: `Erreur d'achat : ${data.message}` });
    });

    socket.on('sellSuccess', () => {
      setNotification({ type: 'success', text: 'Vente réussie !' });
    });

    socket.on('sellError', (data) => {
      setNotification({ type: 'error', text: `Erreur de vente : ${data.message}` });
    });

    return () => {
      socket.off('buySuccess');
      socket.off('buyError');
      socket.off('sellSuccess');
      socket.off('sellError');
    };
  }, [socket]);

  useEffect(() => {
    if (bankAccounts.length > 0 && selectedAccount === null) {
      const defaultAccount = bankAccounts[0];
      setSelectedAccount(defaultAccount.account_name);
      setSelectedAccountBalance(defaultAccount.account_balance);
    }
  }, [bankAccounts, selectedAccount]);

  useEffect(() => {
    if (bankAccounts.length === 0 && selectedAccount == null) {
      if (window !== window.parent) {
        window.parent.postMessage({ type: 'requestBankAccounts' }, 'https://cfx-nui-nolosha_trading');
      }
    }
  }, [bankAccounts, selectedAccount]);

  const handleAccountChange = (event) => {
    const newSelectedAccountName = event.target.value;
    setSelectedAccount(newSelectedAccountName);
    const newSelectedAccount = bankAccounts.find(acc => acc.account_name === newSelectedAccountName);
    if (newSelectedAccount) {
      setSelectedAccountBalance(newSelectedAccount.account_balance);
    }
  };

  return (
    <div style={{ marginTop: '50px' }}>
      <div className="tab-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button
          className={`win95-button ${activeTab === 'buy' ? 'active' : ''}`}
          onClick={() => setActiveTab('buy')}
          style={{ flex: 1, marginRight: '5px' }}
        >
          Formulaire d'achat
        </button>
        <button
          className={`win95-button ${activeTab === 'sell' ? 'active' : ''}`}
          onClick={() => setActiveTab('sell')}
          style={{ flex: 1, marginLeft: '5px' }}
        >
          Formulaire de Vente
        </button>
      </div>
      
      <div className="form-group">
        <label htmlFor="bankAccount">Compte bancaire :</label>
        <select
          id="bankAccount"
          className="win95-select"
          value={selectedAccount || undefined}
          onChange={handleAccountChange}
        >
          {bankAccounts.map(account => (
            <option key={account.account_name} value={account.account_name}>
              {account.account_name} - Solde : ${account.account_balance.toFixed(2)}
            </option>
          ))}
        </select>
      </div>
      {activeTab === 'buy' && (
        <BuyForm 
          stock={stock} 
          userBalance={selectedAccountBalance} 
          leverages={leverages}
          selectedAccount={selectedAccount}
          taxRate={tax}
        />
      )}
      
      {activeTab === 'sell' && (
        <SellForm 
          stock={stock}
          selectedAccount={selectedAccount}
          ownedStocks={ownedStocks}
        />
      )}
  
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.text}
        </div>
      )}

    </div>
  );
}

export default TradeForm;
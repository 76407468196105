import React, { useState, useEffect, useCallback } from 'react';
import { useSocket } from '../SocketContext';
import useStockData from './useStockData';

function StockList({ onSelectStock }) {
  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const socket = useSocket();
  const { chartData } = useStockData(selectedStock);

  useEffect(() => {
    if (!socket) return;

    socket.on('stockPrices', (updatedStocks) => {
      setStocks(prevStocks => {
        if (JSON.stringify(prevStocks) !== JSON.stringify(updatedStocks)) {
          return updatedStocks;
        }
        return prevStocks;
      });
    });

    return () => {
      socket.off('stockPrices');
    };
  }, [socket]);

  const handleStockSelect = useCallback((stock) => {
    if (selectedStock && JSON.stringify(selectedStock) === JSON.stringify(stock)) {
      return; // Évite de sélectionner le même stock
    }
    if (selectedStock) {
      socket.emit('leaveStockRoom', selectedStock.name);
    }
    setSelectedStock(stock);
    onSelectStock(stock);
    socket.emit('joinStockRoom', stock.name);
  }, [selectedStock, socket, onSelectStock]);

  useEffect(() => {
    if (!selectedStock && stocks.length > 0) {
      handleStockSelect(stocks[0]);
    }
  }, [stocks, handleStockSelect, selectedStock, socket]);

  const calculatePriceChange = (stockName) => {
    if (chartData && chartData.length >= 2) {
      const lastCandle = chartData[chartData.length - 1];
      const previousCandle = chartData[chartData.length - 2];
      const priceChange = ((lastCandle.close - previousCandle.close) / previousCandle.close) * 100;
      return priceChange.toFixed(2);
    }
    return null;
  };

  return (
    <div className="stock-list">
      <h3>Liste des Actions</h3>
      <ul>
        {stocks.map(stock => {
          const priceChange = stock.name === selectedStock?.name ? calculatePriceChange(stock.name) : null;
          const changeColor = priceChange > 0 ? 'green' : priceChange < 0 ? 'red' : 'black';

          return (
            <li 
              key={stock.id} 
              onClick={() => handleStockSelect(stock)}
              className={selectedStock && selectedStock.id === stock.id ? 'selected' : ''}
            >
              <div><b>{stock.name}</b> - ${stock.price?.toFixed(2) ?? 'N/A'}</div>
              <span style={{ color: changeColor }}>
                {priceChange !== null ? `${priceChange}%` : '...'}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default StockList;
import React, { useState, useEffect } from 'react';
import './App.css';
import Minesweeper from './Minesweeper';
import StockMarket from './StockMarket';
import Clock from './Clock';
import PhoneDirectory from './PhoneDirectory';

function App() {
  const [isStartMenuOpen, setIsStartMenuOpen] = useState(false);
  const [isMinesweeperOpen, setIsMinesweeperOpen] = useState(false);
  const [isStockMarketOpen, setIsStockMarketOpen] = useState(false);
  const [isPhoneDirectoryOpen, setIsPhoneDirectoryOpen] = useState(false);

  useEffect(() => {
    const handleCloseIframe = () => {
      if (window.self !== window.top) {
        window.parent.postMessage({ type: 'closeIframe' }, 'https://cfx-nui-nolosha_trading');
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleCloseIframe();
      }
    };

    window.addEventListener('closeIframe', handleCloseIframe);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('closeIframe', handleCloseIframe);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const openMinesweeper = () => {
    setIsMinesweeperOpen(true);
  };

  const openStockMarket = () => {
    setIsStockMarketOpen(true);
  };

  const openPhoneDirectory = () => {
    setIsPhoneDirectoryOpen(true);
  };

  return (
      <div className="os-container">
        <div className="desktop">
          <div className="icon">
            <img src="/images/recycle-bin.png" alt="Corbeille" />
            <span>Corbeille</span>
          </div>
          <div className="icon" onClick={openMinesweeper}>
            <img src="/images/minesweeper.png" alt="Démineur" />
            <span>Démineur</span>
          </div>
          <div className="icon" onClick={openStockMarket}>
            <img src="/images/stock-market.png" alt="Bourse" />
            <span>Bourse</span>
          </div>
          <div className="icon" onClick={openPhoneDirectory}>
            <img src="/images/phone-directory.png" alt="Annuaire" />
            <span>Annuaire</span>
          </div>
        </div>
        <div className="taskbar">
          <button className="start-button" onClick={() => setIsStartMenuOpen(!isStartMenuOpen)}>
            Démarrer
          </button>
          {isMinesweeperOpen && (
            <div className="taskbar-item">
              <img src="/images/minesweeper.png" alt="Démineur" />
              <span>Démineur</span>
            </div>
          )}
          {isStockMarketOpen && (
            <div className="taskbar-item">
              <img src="/images/stock-market.png" alt="Bourse" />
              <span>Bourse</span>
            </div>
          )}
          {isPhoneDirectoryOpen && (
            <div className="taskbar-item">
              <img src="/images/phone-directory.png" alt="Annuaire" />
              <span>Annuaire</span>
            </div>
          )}
          <Clock />
        </div>
        {isStartMenuOpen && (
          <div className="start-menu">
            <div className="start-menu-item">Programmes</div>
            <div className="start-menu-item">Documents</div>
            <div className="start-menu-item">Paramètres</div>
            <div className="start-menu-item">Rechercher</div>
            <div className="start-menu-item">Aide</div>
            <div className="start-menu-item">Exécuter...</div>
            <div className="start-menu-item">Arrêter...</div>
          </div>
        )}
        {isMinesweeperOpen && (
          <div className="window">
            <div className="window-title">
              <span>Démineur</span>
              <button className="close-button" onClick={() => setIsMinesweeperOpen(false)}>X</button>
            </div>
            <Minesweeper />
          </div>
        )}
        <div className="window stock-market-window" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: isStockMarketOpen ? "block" : "none"
        }}>
          <div className="window-title">
            <span>Logiciel de Bourse</span>
            <button className="close-button" onClick={() => setIsStockMarketOpen(false)}>X</button>
          </div>
          <StockMarket />
        </div>
        {isPhoneDirectoryOpen && (
          <div className="window phone-directory-window">
            <div className="window-title">
              <span>Annuaire Téléphonique</span>
              <button className="close-button" onClick={() => setIsPhoneDirectoryOpen(false)}>X</button>
            </div>
            <PhoneDirectory />
          </div>
        )}
      </div>
  );
}

export default App;

import React, { createContext, useContext, useState, useEffect } from 'react';

const TradingContext = createContext();

export function TradingProvider({ children }) {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [leverages, setLeverages] = useState([1]);
  const [tax, setTax] = useState([100.0]);
  const [ownedStocks, setOwnedStocks] = useState({});
  const [contacts, setContacts] = useState(window !== window.parent ? [] : [
    { id: 1, name: 'Jean Dupont', phone: '01 23 45 67 89' },
    { id: 2, name: 'Marie Martin', phone: '02 34 56 78 90' },
    { id: 3, name: 'Pierre Durand', phone: '03 45 67 89 01' },
    { id: 4, name: 'Sophie Lefebvre', phone: '04 56 78 90 12' },
    { id: 5, name: 'Luc Moreau', phone: '05 67 89 01 23' },
    { id: 6, name: 'Isabelle Bernard', phone: '06 78 90 12 34' },
    { id: 7, name: 'François Petit', phone: '07 89 01 23 45' },
    { id: 8, name: 'Céline Dubois', phone: '08 90 12 34 56' },
    { id: 9, name: 'Thomas Roux', phone: '09 01 23 45 67' },
    { id: 10, name: 'Nathalie Lambert', phone: '01 12 23 34 45' },
    { id: 11, name: 'Antoine Girard', phone: '02 23 34 45 56' },
    { id: 12, name: 'Émilie Rousseau', phone: '03 34 45 56 67' },
    { id: 13, name: 'Philippe Leroy', phone: '04 45 56 67 78' },
    { id: 14, name: 'Claire Fournier', phone: '05 56 67 78 89' },
    { id: 15, name: 'Julien Mercier', phone: '06 67 78 89 90' },
    { id: 16, name: 'Aurélie Blanc', phone: '07 78 89 90 01' },
    { id: 17, name: 'Sébastien Guerin', phone: '08 89 90 01 12' },
    { id: 18, name: 'Camille Legrand', phone: '09 90 01 12 23' },
    { id: 19, name: 'Mathieu Perrin', phone: '01 01 12 23 34' },
    { id: 20, name: 'Élodie Morel', phone: '02 12 23 34 45' },
    { id: 21, name: 'Nicolas Gauthier', phone: '03 23 34 45 56' },
    { id: 22, name: 'Sandrine Chevalier', phone: '04 34 45 56 67' },
    { id: 23, name: 'Olivier Lemoine', phone: '05 45 56 67 78' },
    { id: 24, name: 'Valérie Bertrand', phone: '06 56 67 78 89' },
    { id: 25, name: 'Romain Garnier', phone: '07 67 78 89 90' },
    { id: 26, name: 'Caroline Faure', phone: '08 78 89 90 01' },
    { id: 27, name: 'Benoît Roussel', phone: '09 89 90 01 12' },
    { id: 28, name: 'Audrey Caron', phone: '01 90 01 12 23' },
    { id: 29, name: 'Fabien Meunier', phone: '02 01 12 23 34' },
    { id: 30, name: 'Stéphanie Laurent', phone: '03 12 23 34 45' },
  ]);

  useEffect(() => {
    function handleMessage(event) {
      switch (event.data.type) {
        case 'bankAccounts':
          setBankAccounts(event.data.accounts);
          setLeverages(event.data.leverages);
          setTax(event.data.tax);
          break;
        case 'ownedStocks':
          setOwnedStocks(event.data.stocks);
          break;
        case 'updateContacts':
          setContacts(event.data.contacts);
          break;
        case 'updateAccounts':
          setBankAccounts(event.data.accounts);
          break;
        default:
          break;
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <TradingContext.Provider value={{ bankAccounts, leverages, tax, ownedStocks, contacts }}>
      {children}
    </TradingContext.Provider>
  );
}

export function useTradingData() {
  return useContext(TradingContext);
}
import { useState, useEffect, useCallback } from 'react';
import { useSocket } from '../SocketContext';

function StockListAuto({ onSelectStock }) {
  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const socket = useSocket();

  const slideDuration = 10000;

  useEffect(() => {
    if (!socket) return;

    socket.on('stockPrices', (updatedStocks) => {
      setStocks(prevStocks => {
        if (JSON.stringify(prevStocks) !== JSON.stringify(updatedStocks)) {
          return updatedStocks;
        }
        return prevStocks;
      });
    });

    return () => {
      socket.off('stockPrices');
    };
  }, [socket]);

  const handleStockSelect = useCallback((stock) => {
    if (selectedStock && JSON.stringify(selectedStock) === JSON.stringify(stock)) {
      return;
    }
    if (selectedStock) {
      socket.emit('leaveStockRoom', selectedStock.name);
    }
    setSelectedStock(stock);
    onSelectStock(stock);
    socket.emit('joinStockRoom', stock.name);
  }, [selectedStock, socket, onSelectStock]);

  useEffect(() => {
    if (!stocks) return;

    const updateCarouselPosition = () => {
      const now = Date.now();

      const startTime = new Date('2024-01-01T00:00:00Z').getTime();
      const elapsedTime = now - startTime;

      const newIndex = Math.floor(elapsedTime / slideDuration) % stocks.length;

      if (selectedStock?.name !== stocks[newIndex]?.name) {
        handleStockSelect(stocks[newIndex]);
      }
    };

    const interval = setInterval(updateCarouselPosition, 100);

    return () => clearInterval(interval);
  }, [stocks, selectedStock, handleStockSelect]);

  useEffect(() => {
    if (!selectedStock && stocks.length > 0) {
      handleStockSelect(stocks[0]);
    }
  }, [stocks, handleStockSelect, selectedStock, socket]);

  return null;
}

export default StockListAuto;
import React, { useState, useEffect, useRef } from 'react';
import {
  ChartCanvas,
  Chart,
  XAxis,
  YAxis,
  CandlestickSeries,
  LineSeries,
  MouseCoordinateX,
  MouseCoordinateY,
  EdgeIndicator,
  CurrentCoordinate,
  OHLCTooltip,
  discontinuousTimeScaleProviderBuilder,
  CrossHairCursor,
  ElderRaySeries,
  lastVisibleItemBasedZoomAnchor,
  MovingAverageTooltip,
  SingleValueTooltip
} from "react-financial-charts";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { elderRay, ema } from "react-financial-charts";
import { retroColors, margin } from './ChartConfig';

import './StockChartView.css';

function StockChartView({ stock, chartData, dimensions, chartRef }) {
  const [simulatedData, setSimulatedData] = useState(chartData);

  const candleFilterRef = useRef(1);

  useEffect(() => {
    setSimulatedData(chartData);
  }, [chartData]);

  useEffect(() => {
    if (!chartData || chartData.length === 0) return;

    const intervalId = setInterval(() => {
      setSimulatedData(prevData => {
        const lastCandle = prevData[prevData.length - 1];
        if (!lastCandle) return prevData;
        const newClose = Math.random() * (lastCandle.high - lastCandle.low) + lastCandle.low;
        const updatedCandle = {
          ...lastCandle,
          close: newClose,
        };
        return [...prevData.slice(0, -1), updatedCandle];
      });
    }, 500);

    return () => {
        clearInterval(intervalId);
    };
  }, [chartData]);

  const { width, height } = dimensions;

  const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
    d => new Date(d.date)
  );

  const ema12 = ema()
    .id(1)
    .options({ windowSize: 12 })
    .merge((d, c) => { d.ema12 = c; })
    .accessor(d => d.ema12);

  const ema26 = ema()
    .id(2)
    .options({ windowSize: 26 })
    .merge((d, c) => { d.ema26 = c; })
    .accessor(d => d.ema26);

  const elder = elderRay();

  // Modifiez cette fonction pour utiliser candleFilterRef
  const setCandleFilter = (value) => {
    candleFilterRef.current = value;
    // Forcez un re-rendu
    setSimulatedData([...simulatedData]);
  };

  // Modifiez cette partie pour utiliser candleFilterRef.current
  const calculatedData = React.useMemo(() => {
    let dataToUse = simulatedData;

    if (candleFilterRef.current !== 1) {
      dataToUse = simulatedData.reduce((acc, _, index, array) => {
        if (index % candleFilterRef.current === 0) {
          const slice = array.slice(index, index + candleFilterRef.current);
          const filteredCandle = {
            date: slice[0].date,
            open: slice[0].open,
            high: Math.max(...slice.map(d => d.high)),
            low: Math.min(...slice.map(d => d.low)),
            close: slice[slice.length - 1].close,
            volume: slice.reduce((sum, d) => sum + d.volume, 0),
          };
          acc.push(filteredCandle);
        }
        return acc;
      }, []);
    }

    return elder(ema26(ema12(dataToUse)));
  }, [simulatedData, ema12, ema26, elder]);

  const elderRayCalculator = elder.accessor();
  const yExtentsCalculator = [
    d => [d.high, d.low],
    ema12.accessor(),
    ema26.accessor()
  ];

  // Utilisez directement calculatedData ici
  const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(calculatedData);

  const xExtents = [
    xAccessor(data[Math.max(0, data.length - 60)]),
    xAccessor(data[data.length - 1]) + 1
  ];

  const gridHeight = height - margin.top - margin.bottom;
  const elderRayHeight = Math.min(100, gridHeight * 0.15);
  const barChartHeight = Math.min(100, gridHeight * 0.15);
  const chartHeight = gridHeight - elderRayHeight - barChartHeight;

  const elderRayOrigin = (_, h) => [0, h - elderRayHeight];

  const pricesDisplayFormat = format(".2f");
  const dateTimeFormat = "%d %b";
  const timeDisplayFormat = timeFormat(dateTimeFormat);

  const openCloseColor = d => d.close > d.open ? retroColors.candleUp : retroColors.candleDown;

  return (
    <div className="stock-chart" ref={chartRef} style={{ width: '100%', height: '100%', backgroundColor: retroColors.background }}>
      <h3 style={{ fontFamily: 'Arial', color: retroColors.text, margin: '0 0 10px 0' }}>
        {stock ? `Évolution du prix de ${stock.name}` : 'Sélectionnez une action'}
      </h3>
      
      <div className="button-container">
        <button className="win95-button" onClick={() => setCandleFilter(1)}>1 minute</button>
        <button className="win95-button" onClick={() => setCandleFilter(30)}>30 minutes</button>
        <button className="win95-button" onClick={() => setCandleFilter(60)}>1 heure</button>
      </div>

      {width > 0 && height > 0 && stock && data && data.length > 0 && (
        <ChartCanvas
          height={height - 40}
          ratio={3}
          width={width}
          margin={margin}
          data={data}
          displayXAccessor={displayXAccessor}
          seriesName={stock.name}
          xScale={xScale}
          xAccessor={xAccessor}
          xExtents={xExtents}
          zoomAnchor={lastVisibleItemBasedZoomAnchor}
        >
          <Chart id={3} height={chartHeight} yExtents={yExtentsCalculator}>
            <XAxis showGridLines gridLinesStrokeStyle={retroColors.gridLines} strokeStyle={retroColors.text} />
            <YAxis ticks={4} showGridLines gridLinesStrokeStyle={retroColors.gridLines} strokeStyle={retroColors.text} tickFormat={pricesDisplayFormat} />
            <CandlestickSeries 
              wickStroke={openCloseColor}
              fill={openCloseColor}
              stroke={openCloseColor}
            />
            <LineSeries yAccessor={ema26.accessor()} strokeStyle={retroColors.ema26} />
            <LineSeries yAccessor={ema12.accessor()} strokeStyle={retroColors.ema12} />
            <CurrentCoordinate yAccessor={ema26.accessor()} fillStyle={retroColors.ema26} />
            <CurrentCoordinate yAccessor={ema12.accessor()} fillStyle={retroColors.ema12} />

            <MouseCoordinateX displayFormat={timeDisplayFormat} />
            <MouseCoordinateY rectWidth={margin.right} displayFormat={pricesDisplayFormat} />

            <EdgeIndicator 
              itemType="last" 
              rectWidth={margin.right} 
              fill={openCloseColor} 
              lineStroke={openCloseColor} 
              displayFormat={pricesDisplayFormat} 
              yAccessor={d => d.close} 
            />
            <OHLCTooltip origin={[8, 16]} />
            <MovingAverageTooltip
              origin={[8, 24]}
              options={[
                {
                  yAccessor: ema26.accessor(),
                  type: "EMA",
                  stroke: retroColors.ema26,
                  windowSize: ema26.options().windowSize
                },
                {
                  yAccessor: ema12.accessor(),
                  type: "EMA",
                  stroke: retroColors.ema12,
                  windowSize: ema12.options().windowSize
                }
              ]}
            />
          </Chart>
          <Chart id={4} height={elderRayHeight} yExtents={elderRayCalculator} origin={elderRayOrigin} padding={{ top: 8, bottom: 8 }}>
            <XAxis showGridLines gridLinesStrokeStyle={retroColors.gridLines} />
            <MouseCoordinateX displayFormat={timeDisplayFormat} />
            <MouseCoordinateY rectWidth={margin.right} displayFormat={pricesDisplayFormat} />
            <ElderRaySeries
              yAccessor={elderRayCalculator}
            />
            <SingleValueTooltip
              yAccessor={elderRayCalculator}
              yLabel="Elder Ray"
              yDisplayFormat={(d) =>
                `${isNaN(d.bullPower) ? "" : pricesDisplayFormat(d.bullPower)}, ${isNaN(d.bearPower) ? "" : pricesDisplayFormat(d.bearPower)}`
              }
              origin={[8, 16]}
            />
          </Chart>
          <CrossHairCursor />
        </ChartCanvas>
      )}
    </div>
  );
}

// Ajoutez cette fonction de comparaison personnalisée
function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.stock === nextProps.stock &&
    prevProps.chartData === nextProps.chartData &&
    prevProps.dimensions.width === nextProps.dimensions.width &&
    prevProps.dimensions.height === nextProps.dimensions.height &&
    prevProps.chartRef === nextProps.chartRef
  );
}

// Exportez le composant mémoïsé
export default React.memo(StockChartView, arePropsEqual);
import React from 'react';
import StockChartView from './Chart/StockChartView';
import useChartDimensions from './useChartDimensions';
import useStockData from './useStockData';

function StockChartContainer({ stock, width, padding = 0 }) {
  const [dimensions, chartRef] = useChartDimensions(width, padding);
  const { chartData, isLoading } = useStockData(stock);

  if (isLoading) {
    return <div className="stock-chart" ref={chartRef}>Chargement des données...</div>;
  }

  if (!chartData) {
    return <div className="stock-chart" ref={chartRef}>Pas de données valides disponibles pour cette action</div>;
  }

  return (
    <StockChartView
      stock={stock}
      chartData={chartData}
      dimensions={dimensions}
      chartRef={chartRef}
    />
  );
}

export default StockChartContainer;